/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: commonUtils.js
Description: Contians all common stateless helper functions used goablly accross the application
*/
import { compare } from "natural-orderby";
import { alphanumCase as alphanumCaseInSentitive } from "./alphanum";
import units from "../constants/units";
export const minDateISOString = "1970-01-01T00:00:00.000Z";
export const DAY_TIME_IN_MS = 86400000;
export const DAY_TIME_IN_SECS = 86400;
export const MOMENT_DATE_FORMAT_WITH_TIME = "DD MMM, HH:mm";
export const MOMENT_DATE_YEAR_WITH_TIME = "DD MMM, YY, HH:mm";
export const MOMENT_TIME_FORMAT = "HH:mm";
export const MOMENT_DATE_FORMAT_WITH_YEAR = "DD MMM, YY";
export const DATE_OBJ_DATE_FORMAT_WITH_YEAR = "dd MMM, yy";
export const DATE_OBJ_DATE_FORMAT_WITH_TIME = "dd MMM, HH:mm";
export const DATE_OBJ_DATE_YEAR_WITH_TIME = "dd MMM, yy, HH:mm";
export const DATE_OBJ_DATE_VALUE_FORMAT = "yyyy-MM-dd";
export const CHART_DATE_FORMATS = {
  dmy: "%d %b, %y",
  dmyt: "%d %b, %y, %H:%M",
  dmyts: "%d %b, %y, %H:%M:%S",
  dmt: "%d %b, %H:%M",
  t: "%H:%M"
};
export const castTimeZoneInMinsToStr = function(timeZone) {
  const offSetInMinutes = Math.abs(timeZone);
  const timeZoneHours = Math.floor(offSetInMinutes / 60);
  const timeZoneMins = Math.floor(offSetInMinutes % 60);
  let timeZoneStr = [timeZoneHours, timeZoneMins]
    .map(x =>
      Math.floor(x)
        .toString()
        .padStart(2, "0")
    )
    .join(":");
  if (timeZone > 0) {
    timeZoneStr = "+" + timeZoneStr;
  } else {
    timeZoneStr = "-" + timeZoneStr;
  }
  return timeZoneStr;
};
export const castISOStringToUserTZ = function(isoString, timeZoneOffset) {
  const tzStr = castTimeZoneInMinsToStr(timeZoneOffset);
  const tempISOString = isoString;
  return tempISOString.replace("Z", tzStr);
};
export const castPondAreaSize = function(size, presentUnits, conversionUnits) {
  if (presentUnits === "ha" && conversionUnits === "ac") {
    return Number((size * 2.47105).toFixed(2));
  } else if (presentUnits === "ac" && conversionUnits === "ha") {
    return Number((size / 2.47105).toFixed(2));
  } else {
    return size === 0 ? 0 : size;
  }
};
export const convertFieldValues = function(model, keys, unit1, unit2) {
  const unitConverter = {
    mins_to_secs: value => value * 60,
    secs_to_mins: value => value / 60
  };
  keys.forEach(
    key => (model[key] = unitConverter[`${unit1}_to_${unit2}`](model[key]))
  );
};
// dynamic units based on user country
// input: value, presentUnits, conversionUnits, countryCode
// output: value in the conversion units
export const getUnitType = function(countryCode) {
  let unitSystem = "";
  if (countryCode === "IN") {
    unitSystem = "METRIC";
  } else {
    unitSystem = "IMPERIAL";
  }
  return unitSystem;
};
export const valueInUserUnits = function(
  value,
  presentUnits,
  conversionUnits,
  type,
  countryCode
) {
  if (presentUnits !== conversionUnits) {
    return units[getUnitType(countryCode)][type].convert(value);
  } else {
    return value;
  }
};
export const objectIdGenerator = function() {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    "UID_" +
    timestamp +
    "xxxxxxxxxxxxxxxx"
      .replace(/[x]/g, function() {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
};
export const getISODateStringFromMomentObj = function(momentObject) {
  return momentObject.format("YYYY-MM-DDTHH:mm:ss.000") + "Z";
};
/**
 * params: tsStr of Format HH:mm
 */
export const isTSLessThanPresentTime = function(timeStr) {
  const presentTime =
    3600 * new Date().getHours() + 60 * new Date().getMinutes();
  const [timeHours, timeMins] = timeStr.split(":");
  const tsVal = 3600 * timeHours + 60 * timeMins;
  if (tsVal <= presentTime) {
    return true;
  } else {
    return false;
  }
};
export const compareTimeStrFormatHHmm = function(timeStr1, timeStr2) {
  const time1 = timeStrHHmmVal(timeStr1);
  const time2 = timeStrHHmmVal(timeStr2);
  const diff = time1 - time2;
  if (diff > 0) {
    return 1;
  } else if (diff < 0) {
    return -1;
  } else {
    return 0;
  }
};
export const timeStrHHmmVal = function(timeStr) {
  const [tsHours, tsMins] = timeStr.split(":");
  const tsVal = 3600 * tsHours + 60 * tsMins;
  return tsVal;
};
export const castSecsHHmmStr = function(timeSecs) {
  const timeMins = timeSecs / 60;
  const hours = Math.floor(timeMins / 60);
  const mins = Math.floor(timeMins % 60);
  const changeXToXXFormat = value => (value > 9 ? value : "0" + value);
  const HHmmStr = changeXToXXFormat(hours) + ":" + changeXToXXFormat(mins);
  return HHmmStr;
};
export const naturalSort = title => {
  return function(a, b) {
    return compare()(a[title], b[title]);
  };
};
export const numericnaturalSort = title => {
  return function(a, b) {
    return compare()(a[title], b[title]);
  };
};
export const alphaNumericComparator = (a, b, chuckSize = -1) => {
  return !a
    ? !b
      ? 0
      : 1
    : !b
    ? -1
    : alphanumCaseInSentitive(
        a.replace(" ", ""),
        b.replace(" ", ""),
        chuckSize
      );
};
export const localizeComparator = (a, b, locale = "en") => {
  return !a
    ? !b
      ? 0
      : 1
    : !b
    ? -1
    : a.localeCompare(b, locale, { numeric: true });
};
export const numericComparator = (a, b, sortDirection) => {
  return a === undefined
    ? b === undefined
      ? 0
      : 1
    : b === undefined
    ? -1
    : +a - +b;
};
export const decodeDeviceCode = function(deviceId_hex) {
  const rightShiftBits = 3;
  const xorKey = 10604510;
  const DecimalValueOfDeviceId = parseInt(deviceId_hex, 16);
  let xr_op = DecimalValueOfDeviceId ^ xorKey;
  for (let i = 1; i <= rightShiftBits; i++) {
    const tempData = xr_op & 0x01;
    xr_op = xr_op >> 1;
    xr_op = xr_op | (tempData << 23);
  }
  xr_op = xr_op & 0xffffff;
  return xr_op;
};
export const getNColorsVaryingHueAndLigthness = function(numOfColors) {
  const arrColors = [];
  const initL = (100 - 20) / numOfColors;
  const initH = (360 - 60) / numOfColors;
  for (let i = 1; i <= numOfColors; i++) {
    arrColors.push(hsl2rgb(initH * i, 96 * 0.01, initL * i * 0.01));
  }
  return arrColors;
};
export const getNColorsVaryingHue = function(numOfColors) {
  const arrColors = [];
  const initH = (360 - 10) / numOfColors;
  // const initL = ()
  for (let i = 1; i <= numOfColors; i++) {
    arrColors.push(hsl2rgb(initH * i, 12 * 0.01, initH * i * 0.01));
  }
  return arrColors;
};
export const stringToColour = function(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(12)).substr(-2);
  }
  return colour;
};
export const getNColorsBasedOnString = function(arrStrings) {
  const colors = {};
  arrStrings.forEach(st => {
    colors[st] = stringToColour(st);
  });
  return colors;
};
export const hsl2rgb = function(h, s, l) {
  const a = s * Math.min(l, 1 - l);
  const f = (n, k = (n + h / 30) % 12) =>
    l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
  return rgbToHex(f(0), f(8), f(4));
};
export const rgbToHex = function(r, g, b) {
  return (
    "#" +
    [r, g, b]
      .map(x =>
        Math.round(x * 255)
          .toString(16)
          .padStart(2, 0)
      )
      .join("")
  );
};
export const getType = function(value) {
  const _toString = Object.prototype.toString;
  return _toString
    .call(value)
    .slice(8, -1)
    .toLowerCase();
};
export const getFieldValueInItem = function(item, field) {
  const keysArr = field.split(".");
  if (keysArr.length > 1) {
    if (item[keysArr[0]] === undefined) {
      throw {
        message: "No key found to Parse",
        field: field,
        item: item
      };
    }
    return getFieldValueInItem(
      item[keysArr[0]],
      field.substr(field.indexOf(".") + 1)
    );
  }
  return item[keysArr[0]];
};
export const setFieldValueInItem = function(item, field, value) {
  const keysArr = field.split(".");
  if (keysArr.length > 1) {
    if (item[keysArr[0]] === undefined) {
      throw {
        message: "No key found to Parse",
        field: field,
        item: item
      };
    }
    return getFieldValueInItem(
      item[keysArr[0]],
      field.substr(field.indexOf(".") + 1)
    );
  }
  item[keysArr[0]] = value;
};
export const validateLanguage = function(langPref) {
  let lang = "";

  switch (langPref) {
    case "en":
      lang = "en";
      break;
    case "es":
      lang = "es";
      break;
    case "pt":
      lang = "pt";
      break;
    case "zh":
      lang = "zh";
      break;
    default:
      lang = "en";
  }

  return lang;
};
export const IQRUpperBound = function(arrValues) {
  const IQRValue = IQR(arrValues);
  const q75Value = Quartile_75(arrValues);
  return Ubound_Quartile(q75Value, IQRValue);
};
export const IQR = function(arrValues) {
  return Quartile_75(arrValues) - Quartile_25(arrValues);
};
export const Ubound_Quartile = function(Quartile_75, IQR) {
  return Quartile_75 + 1.5 * IQR;
};
export const Quartile = function(arrValues, quartile) {
  arrValues.sort((a, b) => a - b);
  const pos = (arrValues.length - 1) * quartile;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (arrValues[base + 1] !== undefined) {
    return arrValues[base] + rest * (arrValues[base + 1] - arrValues[base]);
  } else {
    return arrValues[base];
  }
};
export const Quartile_75 = function(arrValues) {
  return Quartile(arrValues, 0.75);
};
export const Quartile_50 = function(arrValues) {
  return Quartile(arrValues, 0.5);
};
export const Quartile_25 = function(arrValues) {
  return Quartile(arrValues, 0.25);
};
export const isKeyExists = function(item, key) {
  if (!item) return false;
  if (!item[key]) return false;
  return true;
};
export default {
  minDateISOString,
  DAY_TIME_IN_MS,
  DATE_OBJ_DATE_FORMAT_WITH_YEAR,
  DATE_OBJ_DATE_FORMAT_WITH_TIME,
  MOMENT_DATE_FORMAT_WITH_TIME,
  MOMENT_DATE_FORMAT_WITH_YEAR,
  MOMENT_DATE_YEAR_WITH_TIME,
  DATE_OBJ_DATE_VALUE_FORMAT,
  DATE_OBJ_DATE_YEAR_WITH_TIME,
  MOMENT_TIME_FORMAT,
  CHART_DATE_FORMATS,
  getFieldValueInItem,
  objectIdGenerator,
  getISODateStringFromMomentObj,
  isTSLessThanPresentTime,
  timeStrHHmmVal,
  compareTimeStrFormatHHmm,
  castSecsHHmmStr,
  naturalSort,
  // numberWith2DigitPrecision,
  localizeComparator,
  alphaNumericComparator,
  numericComparator,
  decodeDeviceCode,
  getNColorsVaryingHue,
  getNColorsVaryingHueAndLigthness,
  stringToColour,
  getNColorsBasedOnString,
  castPondAreaSize,
  castTimeZoneInMinsToStr,
  getType,
  castISOStringToUserTZ,
  convertFieldValues,
  valueInUserUnits,
  getUnitType,
  IQRUpperBound,
  Quartile_50,
  IQR,
  validateLanguage,
  isKeyExists
};
